





















































import { locales } from '@/i18n';
import ServiceUtils, { Service } from '@/utils/ServiceUtils';
import Vue from 'vue';
import FunctionalCenterForm from './FunctionalCenterForm.vue';
import ServiceCharacteristicPicker from '@/components/serviceCharacteristic/ServiceCharacteristicPicker.vue';

export default Vue.extend({
  components: {
    FunctionalCenterForm,
    ServiceCharacteristicPicker,
  },
  props: {
    value: {
      type: Object as () => Partial<Service>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<Service> {
        return this.value || ServiceUtils.service.generateDefault();
      },
      set(val: Partial<Service>) {
        this.$emit('input', val);
      },
    },

    locales() {
      return locales;
    },
  },
});
