import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';
import SearchTermUtils, { SearchTerm } from './SearchTermUtils';
import ServiceCharacteristicUtils, {
  ServiceCharacteristic,
} from './ServiceCharacteristicUtils';

interface Service {
  id: number;
  key: string;
  name: LanguageObject;
  hidden: boolean;
  search_term: Partial<SearchTerm>;
  functional_centre: Partial<FunctionalCentre>;
  language_capabilities: {
    [locale: string]: string | undefined;
  };
  service_characteristics: Partial<ServiceCharacteristic>[];
  use_count: number;
}

interface FunctionalCentre {
  code: string;
  name: LanguageObject;
}

const language_service_keys = ['en', 'fr'];

const generateLanguageServices = (language_capabilities?: {
  [locale: string]: string | undefined;
}) => {
  const result: any = {};
  language_service_keys?.forEach((locale) => {
    result[locale] = language_capabilities
      ? language_capabilities[locale]
      : undefined;
  });
  return result;
};

const service = {
  generateDefault: (item?: Partial<Service>): Partial<Service> => {
    return {
      id: item?.id,
      key: item?.key,
      name: LocaleUtils.langobj.generate(item?.name),
      hidden: item?.hidden,
      search_term: SearchTermUtils.searchTerm.generateDefault({
        ...item?.search_term,
        parent_type: 'service',
        parent_id: item?.id,
      }),
      functional_centre: functional_centre.generateDefault(
        item?.functional_centre
      ),
      language_capabilities: generateLanguageServices(
        item?.language_capabilities
      ),
      service_characteristics: (item?.service_characteristics || [])?.map(
        (value) =>
          ServiceCharacteristicUtils.serviceCharacteristic.generateDefault(
            value
          )
      ),
      use_count: item?.use_count,
    };
  },

  api: {
    list: async (params?: { temp: string }) => {
      const resp = await axios.get<Service[]>('service/list.php', {
        params: params,
      });
      return resp.data;
    },
    get: async (value: number | string) => {
      const resp = await axios.get<Service>('service/get.php', {
        params: typeof value === 'number' ? { id: value } : { key: value },
      });
      return resp.data;
    },
    create: async (item: Partial<Service>) => {
      const resp = await axios.post<Service>('service/create.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    update: async (item: Partial<Service>) => {
      const resp = await axios.put<Service>('service/update.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    remove: async (id: number) => {
      const resp = await axios.delete<Service>('service/remove.php', {
        params: { id: id },
      });
      return resp.data;
    },
  },
};

const functional_centre = {
  generateDefault: (
    item?: Partial<FunctionalCentre>
  ): Partial<FunctionalCentre> => {
    return {
      code: item?.code,
      name: LocaleUtils.langobj.generate(item?.name),
    };
  },
};

export default {
  service,
  functional_centre,
};
export { Service, FunctionalCentre };
