




































import { locales } from '@/i18n';
import LocaleUtils from '@/utils/LocaleUtils';
import ServiceUtils, { FunctionalCentre } from '@/utils/ServiceUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<FunctionalCentre>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<FunctionalCentre> {
        return this.value || ServiceUtils.functional_centre.generateDefault();
      },
      set(val: Partial<FunctionalCentre>) {
        this.$emit('input', val);
      },
    },

    locales() {
      return locales;
    },

    enabled: {
      get(): boolean {
        return this.internal.code !== undefined;
      },
      set(val: boolean) {
        this.internal.code = val ? '' : undefined;
        this.internal.name = LocaleUtils.langobj.generate();
      },
    },
  },
});
