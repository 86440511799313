





























import ServiceCharacteristicUtils, {
  ServiceCharacteristic,
} from '@/utils/ServiceCharacteristicUtils';
import { isArray } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [
        Object as () => ServiceCharacteristic,
        Array as () => ServiceCharacteristic[],
      ],
    },
    label: {
      type: String as () => string,
    },
    errorMessages: {
      type: Array as () => string[],
    },
    loading: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): ServiceCharacteristic | ServiceCharacteristic[] | undefined {
        if (isArray(this.value)) {
          return this.items?.filter((item) =>
            (this.value as ServiceCharacteristic[]).some((val) =>
              this.compare(item, val)
            )
          );
        } else {
          return this.items?.find((item) =>
            this.compare(item, this.value as ServiceCharacteristic)
          );
        }
      },
      set(item: ServiceCharacteristic) {
        this.$emit('input', item);
      },
    },
  },

  data: (): {
    items?: ServiceCharacteristic[];
    selected?: string[];
  } => ({
    selected: [],
    items: undefined,
  }),

  async mounted() {
    try {
      this.items =
        await ServiceCharacteristicUtils.serviceCharacteristic.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    compare(item: ServiceCharacteristic, value: ServiceCharacteristic) {
      // console.log(item,value);
      return item.id === value?.id || item.key === value?.key;
    },
  },
});
