import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface SearchTerm {
  id: number;
  parent_type: string;
  parent_id?: number;
  parent_key?: string;
  terms: LanguageObject;
}

const searchTerm = {
  generateDefault: (item?: Partial<SearchTerm>): Partial<SearchTerm> => {
    return {
      id: item?.id,
      parent_type: item?.parent_type,
      parent_id: item?.parent_id,
      parent_key: item?.parent_key,
      terms: LocaleUtils.langobj.generate(item?.terms),
    };
  },
};

export default { searchTerm };
export { SearchTerm };
