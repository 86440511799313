import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';
import { Service } from './ServiceUtils';

interface ServiceCharacteristic {
  id: number;
  key: string;
  name_short: LanguageObject;
  name_long: LanguageObject;
  mutually_exclusive: boolean;
  natural_order: number;
}

interface ServiceHolder {
  characteristic: Partial<ServiceCharacteristic>;
  items: Partial<Service>[];
}

const serviceCharacteristic = {
  generateDefault: (
    item?: Partial<ServiceCharacteristic>
  ): Partial<ServiceCharacteristic> => {
    return {
      id: item?.id,
      key: item?.key,
      name_short: LocaleUtils.langobj.generate(item?.name_short),
      name_long: LocaleUtils.langobj.generate(item?.name_long),
      mutually_exclusive: item?.mutually_exclusive,
      natural_order: item?.natural_order,
    };
  },

  api: {
    list: async (params?: { temp: string }) => {
      const resp = await axios.get<ServiceCharacteristic[]>(
        'serviceCharacteristic/list.php',
        {
          params: params,
        }
      );
      return resp.data;
    },
    get: async (value: number | string) => {
      const resp = await axios.get<ServiceCharacteristic>(
        'serviceCharacteristic/get.php',
        {
          params: typeof value === 'number' ? { id: value } : { key: value },
        }
      );
      return resp.data;
    },
    create: async (item: Partial<ServiceCharacteristic>) => {
      const resp = await axios.post<ServiceCharacteristic>(
        'serviceCharacteristic/create.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    update: async (item: Partial<ServiceCharacteristic>) => {
      const resp = await axios.put<ServiceCharacteristic>(
        'serviceCharacteristic/update.php',
        {
          ...APIUtils.clean(item),
        }
      );
      return resp.data;
    },
    remove: async (id: number) => {
      const resp = await axios.delete<ServiceCharacteristic>(
        'serviceCharacteristic/remove.php',
        {
          params: { id: id },
        }
      );
      return resp.data;
    },
  },
};

export default { serviceCharacteristic };
export { ServiceCharacteristic, ServiceHolder };
